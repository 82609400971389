body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --mdc-theme-on-primary: teal;
  --mdc-theme-on-secondary: black;
}

html, head, body, div#root {
  position: static;
  margin: 0px !important;
  padding: 0 !important;
  border: 0 !important;
  top: 0px;
}

body {
  overscroll-behavior-y: contain;
}

h1, h2, h3, h4, h5, h6 {
  margin: 10px 0px 10px 0px;
}

p {
  font-size: 1.1em;
}

/* Make disabled inputs darker, esp on iOS and Safari */
input:not([type="checkbox"]):not([type="radio"]):disabled, textarea:disabled {
  opacity: 1;
  color: #555;
}

.fit-content {
  width: fit-content;
  width: -moz-fit-content;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-screenshots {
  max-height: 50vh;
  max-width: 95%;
  pointer-events: none;
}

.App-gifs {
  max-height: 50vh;
  max-width: 95%;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-1000px {
  margin: 0 auto;
  max-width: 1000px;
}

.App-hero {
  background-color: var(--mdc-theme-primary);
  padding: 0.75rem;
}

.App-page {
  padding-top: 25px;
}

.App-footer {
  background-color: lightgray;
  color: gray;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  34% {
    opacity: 0;
  }
  70% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

.App-Logo {
  width: 90%;
  margin: 1rem;
}

@media (max-width: 839px) {
  .mdc-top-app-bar:not(.mdc-top-app-bar--short-collapsed) .App-AuthedHeaderComponent button.mdc-top-app-bar__action-item {
    display: none;
  }
  .mdc-top-app-bar--short-collapsed .App-Title {
    visibility: hidden;
    display: none;
  }
  .mdc-top-app-bar .App-AuthedHeaderComponent button:not(.mdc-top-app-bar__action-item) {
    visibility: hidden;
    display: none;
  }
  .App-Logo {
    width: 45% !important;
    margin: 0 !important;
  }
}

@media (min-width: 840px) {
  .App-AuthedHeaderComponent {
    visibility: visible;
    display: inline-flex;
  }
  .mdc-top-app-bar:not(.mdc-top-app-bar--short-collapsed) .App-AuthedHeaderComponent button:not(.mdc-top-app-bar__action-item) {
    animation: 750ms fadeIn;
    animation-fill-mode: forwards;
  }
  .mdc-top-app-bar:not(.mdc-top-app-bar--short-collapsed) .App-AuthedHeaderComponent button.mdc-top-app-bar__action-item {
    display: none;
  }
  .mdc-top-app-bar--short-collapsed .App-Title {
    visibility: hidden;
    display: none;
  }
  .mdc-top-app-bar--short-collapsed .App-AuthedHeaderComponent button:not(.mdc-top-app-bar__action-item) {
    visibility: hidden;
    display: none;
  }
}

.card-holder, .card {
  display: inline-block;
}
.card {
  position: relative;
}
.card > .card-image {
  /* Remove space around it due to inline line height */
  display: block;
}
.card.annotated {
  --annotation-size: 1.5em;
  --annotation-margin: 2px;
  margin-top: calc(var(--annotation-size) + var(--annotation-margin));
}
.card .card-overlay {
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  pointer-events: none;
}
.card .card-overlay.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card .card-overlay.card-annotation {
  pointer-events: inherit;
  top: calc(-1 * (var(--annotation-size) + var(--annotation-margin)));
  height: var(--annotation-size);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.noscroll .rmwc-collapsible-list__children-inner {
  overflow: visible;
}

@media (max-width: 600px) {
  .card-hand {
    padding-left: var(--card-overlap-padding) !important;
    padding-right: var(--card-overlap-padding) !important;
  }
  .card-hand .card-holder {
    transform: translate(0,0) !important;
    margin-bottom: calc(var(--card-curve-bottom) * -1);
  }
  .card-hand .card-holder.selected {
    transform: translateY(calc(var(--card-select-dist) * -1)) !important;
  }
}

.card .card-overlay.card-badge {
  position: absolute;
  width: 24px;
  height: 19px;
  padding: 5px 0 0 0;
  left: -12px;
  top: -12px;
  background: #8aa7c3;
  border-radius: 50%;
  font-weight: 700;
  color: white;
}

.card .card-overlay .marker {
  background: yellow;
  position: relative;
  border-radius: 50%;
  width: 80%;
  height: auto;
  padding-top: 40%;
  padding-bottom: 40%;
  font-weight: bolder;
}

.card-image > use {
  fill: var(--card-fill, white);
}

.avatar-progress {
  position: relative;
  width: min-content;
  height: min-content;
  margin: 4px;
}
.avatar-progress > .rmwc-avatar {
}
.avatar-progress > .rmwc-circular-progress {
  transform: scale(1.3);
  position: absolute;
  left: 0; top: 0;
  pointer-events: none;
}
.avatar-progress .rmwc-circular-progress__path {
  stroke: var(--stroke-color, limegreen);
}
.avatar-progress--large .rmwc-circular-progress__path {
  stroke-width: 0.2rem;
}

.rmwc-tooltip {
  font-size: 1rem !important;
}

/* Adapted from w3schools :P */
.flip-card {
  position: relative;
  width: min-content;
  height: min-content;
  perspective: 500px; /* Remove this if you don't want the 3D effect */
}
.flip-card-inner {
  transition: transform 0.8s;
  transform-style: preserve-3d;
}
.flip-card .flip-card-checkbox {
  display: none;
}
.flip-card .flip-card-checkbox:checked ~ .flip-card-inner {
  transform: rotateY(180deg);
}
@media (hover: hover) and (pointer: fine) {
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  .flip-card:hover .flip-card-checkbox:checked ~ .flip-card-inner {
    transform: rotateY(0deg);
  }
}
.flip-card .flip-card-inner > * {
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}
.flip-card .flip-card-inner > :not(:first-child) {
  position: absolute;
  left: 0; top: 0;
  width: 100%; height: 100%;
  transform: rotateY(180deg);
}

.rotate-card {
  position: relative;
  width: min-content;
  height: min-content;
}
.rotate-card-inner {
  transform-origin: center;
  transition: transform 0.8s;
  /* prevent jitter when mouse does/does not intersect bounding box while rotating */
  pointer-events: none;
}
.rotate-card .rotate-card-checkbox {
  display: none;
}
.rotate-card .rotate-card-checkbox:checked ~ .rotate-card-inner {
  transform: rotate(180deg);
}
@media (hover: hover) and (pointer: fine) {
  .rotate-card:hover .rotate-card-inner {
    transform: rotate(180deg);
  }
  .rotate-card:hover .rotate-card-checkbox:checked ~ .rotate-card-inner {
    transform: rotate(0deg);
  }
}

/* TODO: upstream??? */
.mdc-top-app-bar--short + .mdc-top-app-bar--fixed-adjust {
  padding-top: 56px;
}
.mdc-select, .mdc-text-field {
  --mdc-theme-primary: var(--mdc-theme-secondary);
}
.mdc-select--focused .mdc-select__dropdown-icon {
  background: url(data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20style%3D%22fill%3A%23006515%22%20fill-rule%3D%22evenodd%22%20opacity%3D%221%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E) no-repeat center !important;
}

/* Some styles for more responsive thingies for list items, esp. on archive */
.double-info.mdc-list-item__text > .mdc-list-item__primary-text {
  position: relative;
  top: -10px;
}
.mdc-list-item__text.double-info {
  flex-grow: 0.2;
  text-align: left;
}
.mdc-list-item__text.double-info > .mdc-list-item__secondary-text {
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  max-width: 250px;
  position: relative;
  top: -10px;
}
.mdc-list-item__text.double-info > .mdc-list-item__secondary-text:before {
  content: none;
}
.mdc-list-item__text.double-info > .mdc-list-item__secondary-text .info-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mdc-list-item__text.double-info > .mdc-list-item__secondary-text .info-spacer {
  display: inline-block;
  width: 1.5em;
}
.mdc-list-item__meta.double-button {
  flex-grow: 1;
  flex-basis: 0;
  text-align: right;
  max-width: max-content;
}

/* Let the hero portion overflow the top app bar so the background is
   the primary color when it overflows.
 */
.mdc-top-app-bar--short + .mdc-top-app-bar--fixed-adjust ~ .App-hero::before {
  content: "";
  display: block;
  padding: 56px;
}
.mdc-top-app-bar--short + .mdc-top-app-bar--fixed-adjust ~ .App-hero {
  margin-top: -56px;
}

.mdc-snackbar__action:not(:disabled) {
  color: var(--mdc-theme-primary, #6200ee);
}

.mdc-top-app-bar--fixed.mdc-top-app-bar--short {
  transition: box-shadow 200ms linear, width 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-top-app-bar:not(.mdc-top-app-bar--short-collapsed) .mdc-top-app-bar__title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mdc-top-app-bar {
  max-width: 100vw;
}

/* Prevent app title text from wrapping as it expands from collapsed state */
.mdc-top-app-bar .App-Title .mdc-button__label {
  white-space: nowrap;
  overflow: hidden;
}

.mdc-layout-grid {
  max-width: 100vw;
}

/* Prevent buttons in the meta space from text wrapping and being compresed on
   mobile devices. */
.mdc-list-item__meta Button.mdc-button {
  white-space: nowrap;
  margin: 2px;
}

a, a:visited {
  color: black;
}

.App-game-code {
  width: 90%;
}

article.text p, article.text ul {
  text-align: left;
}

.text-left {
  text-align: left;
}

.flexbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.flexible {
  margin: 1rem;
}

.leftpad {
  margin-left: 2rem;
}

.playerSummary {
  display: inline;
  width: 100px;
  margin-right: 25px;
}

.playerSummaryInHand {
  margin-left: 0.5rem;
}

.unselectable {
  user-select: none;
}

.results li {
  list-style: none;
  list-style-type: none;
}

.scrollable-x {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

#wpg-logo {
  text-align: center;
}

.mdc-snackbar {
  bottom: unset;
  top: 0px;
}

nav a {
  text-decoration: none;
}

.countdown-overlay {
  display: block;
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;

  z-index: 1000;
}

.countdown-circle {
  display: block;
  width: 45vmin;
  height: 45vmin;

  position: relative;
  margin: auto auto;
  top: calc(50vh - 22.5vmin);

  border-radius: 50%;

  line-height: 45vmin;
  font-size: 40vmin;

  background-color: #006515;
  opacity: 0.85;
}

hr {
  width: 100%;
  color: rgba(0, 0, 0, 0.12);
  background-color: rgba(0, 0, 0, 0.12);
  border-top: none;
  border-left: none;
  border-right: none;
}

.vertical-align-middle {
  vertical-align: middle;
}
